import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useNavigate, useLocation } from 'react-router-dom';


// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger);

// Product design projects data
const productDesignProjects = [
  {
    id: 10,
    name: 'Health App',
    client: 'VITALITY HEALTH',
    image: '/images/vitality/health-app-thumb.png',
    year: '2024',
    skills: ['concept design', 'branding', 'ux/ui', 'development'],
    tools: ['figma', 'framer', 'react']
  },
  {
    id: 11,
    name: 'E-commerce Platform',
    client: 'VERDE MARKET',
    image: '/images/verde/verde-market-thumb.png',
    year: '2025',
    skills: ['concept design', 'ux research', 'ui design', 'marketing'],
    tools: ['figma', 'miro', 'illustrator'],
    liveLink: 'https://www.figma.com/proto/uLWAsONKTcmhrZC0z0zvdS/Project'
  },
  {
    id: 12,
    name: 'Financial Dashboard',
    client: 'NEXUS FINANCE',
    image: '/images/nexus/nexus-finance-thumb.png',
    year: '2024',
    skills: ['concept design', 'branding', 'ux/ui', 'prototyping'],
    tools: ['figma', 'photoshop', 'after effects']
  },

];

// Tool icon mapping (you would replace these with actual imports/paths)
const getToolIcon = (tool) => {
  const icons = {
    figma: '/icons/figma.svg',
    photoshop: '/icons/photoshop.svg',
    illustrator: '/icons/illustrator.svg',
    webflow: '/icons/webflow.svg',
    framer: '/icons/framer.svg',
    miro: '/icons/miro.svg',
    react: '/icons/react.svg',
    tailwind: '/icons/tailwind.svg',
    prototype: '/icons/prototype.svg',
    'after effects': '/icons/after-effects.svg'
  };
  
  return icons[tool.toLowerCase()] || '/icons/default-tool.svg';
};

const ProductDesignSection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const sectionRef = useRef(null);
  const projectRefs = useRef([]);
  const [hoveredProject, setHoveredProject] = useState(null);

  // Function to handle navigation to project page
  const handleViewProject = (projectId) => {
    navigate(`/project/${projectId}`, { 
      state: { from: location.pathname } 
    });
  };

  useEffect(() => {
    // Create a GSAP context to easily kill all animations later
    const ctx = gsap.context(() => {
      // Animate product design projects
      projectRefs.current.forEach((projectEl, index) => {
        if (!projectEl) return;
        
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: projectEl,
            start: "top 85%",
            end: "top 60%",
            scrub: 0.2,
            pin: false,
            anticipatePin: 1
          }
        });

        tl.fromTo(projectEl, {
          rotationX: 15,
          transformOrigin: "bottom center",
          opacity: 0.5,
          scale: 0.9,
          y: 40
        },
        {
          rotationX: 0,
          opacity: 1,
          scale: 1,
          y: 0,
          duration: 0.4,
          ease: "power2.out"
        });
      });
    });

    // Cleanup function
    return () => ctx.revert();
  }, []);

  return (
    <div ref={sectionRef} className="bg-white py-16" id="product-design">
      <div className="container mx-auto px-4">
        {/* Product Design Section */}
        <h2 className="text-5xl md:text-7xl text-center font-bold mb-4 mt-16 about-text">
          PRODUCT DESIGN
        </h2>
        <p className="text-xl text-center text-gray-700 mb-12 max-w-3xl mx-auto">
          These products are <strong>end-to-end work</strong>: concept, brand, UX/UI, sometimes even development and marketing.
        </p>
        
        <div 
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10" 
          style={{perspective: "1500px"}}
        >
          {productDesignProjects.map((project, index) => (
            <div
              key={index}
              ref={el => projectRefs.current[index] = el}
              className="portfolio-card relative overflow-hidden rounded-xl shadow-lg w-full [transform-style:preserve-3d] border-2 border-black cursor-pointer"
              style={{
                transformOrigin: "bottom center",
                willChange: "transform, opacity",
                height: "500px" // Medium-sized card, between featured and additional
              }}
              onClick={() => handleViewProject(project.id)}
              onMouseEnter={() => setHoveredProject(index)}
              onMouseLeave={() => setHoveredProject(null)}
            >
              {/* White header section with project information */}
              <div className="absolute top-0 left-0 right-0 bg-white p-6 z-10" style={{ height: "38%" }}>
                <div className="flex justify-between h-full">
                  {/* Left column: Project title and client */}
                  <div className="flex flex-col justify-between">
                    <div>
                      <h3 className="text-2xl font-bold mb-2 text-black">{project.name}</h3>
                      <p className="text-base text-gray-700">{project.client}</p>
                    </div>
                    
                    {/* Project skills - more detailed than additional but more compact than featured */}
                    <div className="mt-3">
                      {project.skills.map((skill, i) => (
                        <span key={i} className="text-sm text-gray-600 block">{skill}</span>
                      ))}
                    </div>
                  </div>
                  
                  {/* Right column: Year and tool icons */}
                  <div className="flex flex-col items-end justify-between">
                    <div className="text-xl font-bold text-black">{project.year}</div>
                    
                    {/* Tool icons */}
                    <div className="flex gap-2 mt-3">
                      {project.tools.map((tool, i) => (
                        <div key={i} className="w-5 h-5">
                          <img 
                            src={getToolIcon(tool)} 
                            alt={tool} 
                            className="w-full h-full object-contain"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Project image section */}
              <div className="absolute bottom-0 left-0 right-0" style={{ height: "62%", top: "38%" }}>
                <img
                  src={project.image}
                  alt={project.name}
                  className="w-full h-full object-cover"
                />
              </div>
              
              {/* Hover overlay with "View" button */}
              <div 
                className={`absolute inset-0 bg-black/50 flex items-center justify-center transition-opacity duration-300 ${
                  hoveredProject === index ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <div className="rounded-full bg-white px-5 py-2 text-black font-medium">
                  View
                </div>
              </div>
              
              {/* Live link if available (display on hover) */}
              {project.liveLink && (
                <div 
                  className={`absolute bottom-6 right-6 transition-opacity duration-300 ${
                    hoveredProject === index ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  <a
                    href={project.liveLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white underline hover:text-gray-300"
                    onClick={(e) => e.stopPropagation()}
                  >
                    See Live Project
                  </a>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductDesignSection;