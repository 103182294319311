import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import projectsData from './design-projects';
import { X, ArrowLeft, ArrowRight, ChevronLeft, ChevronRight, Maximize, Minimize } from 'lucide-react';

const ProjectPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useParams();
  const id = parseInt(projectId);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fullscreenMode, setFullscreenMode] = useState(false);
  
  // Store the previous location to return to
  const previousPath = location.state?.from || '/';

  // Projects data reference (this will be imported from your file)
  // <projectsData />

  // Find the current project based on ID
  const project = projectsData.find(p => p.id === id) || projectsData[0];

  // Find adjacent projects for navigation
  const nextProject = projectsData.find(p => p.id > id) || projectsData[0];
  const prevProject = projectsData.find(p => p.id < id) || projectsData[projectsData.length - 1];

  useEffect(() => {
    // Prevent scrolling on the background when modal is open
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  // Handle image navigation
  const goToNextImage = () => {
    setCurrentImageIndex((prev) => 
      prev === project.gallery?.length - 1 ? 0 : prev + 1
    );
  };

  const goToPrevImage = () => {
    setCurrentImageIndex((prev) => 
      prev === 0 ? project.gallery?.length - 1 : prev - 1
    );
  };

  const toggleFullscreen = () => {
    setFullscreenMode(!fullscreenMode);
  };

  const handleClose = () => {
    // Navigate to the latest works section instead of UX
    navigate('/ux');
     // Use setTimeout to ensure navigation completes before scrolling
  setTimeout(() => {
    const projectsSection = document.getElementById('latest-works');
    if (projectsSection) {
      projectsSection.scrollIntoView({ behavior: 'smooth' });
    }
  }, 100);
  };

  
  const handleNextProject = (e) => {
    e.stopPropagation();
    navigate(`/project/${nextProject.id}`, { state: { from: previousPath } });
  };

  const handlePrevProject = (e) => {
    e.stopPropagation();
    navigate(`/project/${prevProject.id}`, { state: { from: previousPath } });
  };

  const pageVariants = {
    initial: {
      opacity: 0,
      y: 20
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.22, 1, 0.36, 1]
      }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.4
      }
    }
  };

  const staggerChildVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 }
  };

  const containerVariants = {
    animate: {
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const getMediaType = (url) => {
    if (!url) return 'image';
    if (url.endsWith('.mp4')) return 'video';
    return 'image';
  };

  // Fullscreen media viewer component
  const FullScreenMediaViewer = () => {
    if (!fullscreenMode || !project.gallery?.length) return null;
    
    const currentMedia = project.gallery[currentImageIndex];
    const isVideo = getMediaType(currentMedia) === 'video';
    
    return (
      <div className="fixed inset-0 bg-black z-50 flex items-center justify-center">
        <button
          onClick={toggleFullscreen}
          className="absolute top-4 right-4 text-white p-2 rounded-full bg-gray-800 bg-opacity-50 hover:bg-opacity-70 transition-colors"
          aria-label="Exit fullscreen"
        >
          <Minimize className="w-6 h-6" />
        </button>
        
        <button
          onClick={goToPrevImage}
          className="absolute left-4 text-white p-2 rounded-full bg-gray-800 bg-opacity-50 hover:bg-opacity-70 transition-colors"
          aria-label="Previous media"
        >
          <ChevronLeft className="w-8 h-8" />
        </button>
        
        {isVideo ? (
          <video 
            src={currentMedia} 
            alt={`${project.title} - video ${currentImageIndex + 1}`}
            className="max-h-screen max-w-screen object-contain"
            controls
            autoPlay
            loop
          />
        ) : (
          <img 
            src={currentMedia} 
            alt={`${project.title} - image ${currentImageIndex + 1}`}
            className="max-h-screen max-w-screen object-contain"
          />
        )}
        
        <button
          onClick={goToNextImage}
          className="absolute right-4 text-white p-2 rounded-full bg-gray-800 bg-opacity-50 hover:bg-opacity-70 transition-colors"
          aria-label="Next media"
        >
          <ChevronRight className="w-8 h-8" />
        </button>
        
        <div className="absolute bottom-4 text-white text-center w-full">
          <p className="font-medium">{currentImageIndex + 1} / {project.gallery.length}</p>
        </div>
      </div>
    );
  };

  // Media renderer component for inline images/videos throughout sections
  const MediaRenderer = ({ src, caption }) => {
    if (!src) return null;
    
    const isVideo = getMediaType(src) === 'video';
    
    return (
      <div className="my-12">
        <div className="rounded-lg overflow-hidden bg-gray-100">
          {isVideo ? (
            <video 
              src={src} 
              className="w-full object-contain mx-auto" 
              style={{ maxHeight: '70vh' }}
              controls
              autoPlay
              loop
            />
          ) : (
            <img 
              src={src} 
              className="w-full object-contain mx-auto" 
              style={{ maxHeight: '70vh' }}
              alt={caption || "Project image"}
            />
          )}
        </div>
        {caption && (
          <p className="text-sm text-gray-500 mt-2 text-center">{caption}</p>
        )}
      </div>
    );
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div 
        className="fixed inset-0 bg-white z-50 overflow-y-auto"
        initial="initial"
        animate="animate"
        exit="exit"
        variants={pageVariants}
      >
        {/* Top navigation bar with bold close button */}
        <div className="sticky top-0 z-10 bg-white bg-opacity-90 backdrop-blur-sm border-b border-gray-100">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center h-16">
              <button 
                onClick={handleClose}
                className="flex items-center text-gray-600 hover:text-black transition-colors"
              >
                <ArrowLeft className="w-5 h-5 mr-2" />
                <span className="font-medium">Ariane Souza</span>
              </button>
              <button 
                onClick={handleClose}
                className="p-3 rounded-full bg-black hover:bg-gray-800 transition-colors"
                aria-label="Close project"
              >
                <X className="w-6 h-6 text-white" />
              </button>
            </div>
          </div>
        </div>

        {/* Project content */}
        <motion.div 
          variants={containerVariants}
        >
          {/* Full width hero image */}
          <motion.div variants={staggerChildVariants} className="w-full relative">
            <img 
              src={project.heroImage} 
              alt={project.title} 
              className="w-full h-96 md:h-screen object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-60"></div>
            <div className="absolute bottom-0 left-0 w-full p-8 md:p-16 text-white">
              <p className="text-gray-200 font-medium mb-2">Project</p>
              <h1 className="text-4xl md:text-6xl font-light mb-6 md:mb-12 leading-tight max-w-4xl">{project.title}</h1>
            </div>
          </motion.div>

          {/* Project details */}
          <motion.div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
            {/* Project overview and metadata */}
            <motion.section variants={staggerChildVariants} className="mb-24">
              <div className="grid grid-cols-1 gap-12">
                <motion.div variants={staggerChildVariants}>
                  <h2 className="text-sm text-gray-400 uppercase tracking-wider mb-4">Project Overview</h2>
                  <div 
                    className="text-xl leading-relaxed text-gray-700 mb-12"
                    dangerouslySetInnerHTML={{ __html: project.overview }}
                  />
                
                  <div className="grid grid-cols-2 sm:grid-cols-4 gap-8 mb-12">
                    <div>
                      <h3 className="text-sm text-gray-400 uppercase tracking-wider mb-2">Client</h3>
                      <p className="font-medium text-gray-700">{project.client}</p>
                    </div>
                    <div>
                      <h3 className="text-sm text-gray-400 uppercase tracking-wider mb-2">Year</h3>
                      <p className="font-medium text-gray-700">{project.year}</p>
                    </div>
                    <div>
                      <h3 className="text-sm text-gray-400 uppercase tracking-wider mb-2">Role</h3>
                      <p className="font-medium text-gray-700">{project.role}</p>
                    </div>
                    <div>
                      <h3 className="text-sm text-gray-400 uppercase tracking-wider mb-2">Tools</h3>
                      <p className="font-medium text-gray-700">{project.tools || "Not specified"}</p>
                    </div>
                  </div>
                  
                  {/* Skills section */}
                  {project.skills && project.skills.length > 0 && (
                    <div>
                      <h3 className="text-sm text-gray-400 uppercase tracking-wider mb-4">Skills</h3>
                      <div className="flex flex-wrap gap-2">
                        {project.skills.map((skill, index) => (
                          <span 
                            key={index} 
                            className="inline-block px-3 py-1 bg-gray-100 text-gray-600 rounded-full text-sm font-medium"
                          >
                            {skill}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </motion.div>
              </div>
            </motion.section>

            {/* Challenge section with optional media */}
            <motion.section variants={staggerChildVariants} className="mb-24">
              <motion.h2 variants={staggerChildVariants} className="text-sm text-gray-400 uppercase tracking-wider mb-6">The Problem</motion.h2>
              <motion.div variants={staggerChildVariants} className="mb-12">
                <p className="text-xl leading-relaxed text-gray-700">
                  {project.challenge}
                </p>
              </motion.div>
              
              {/* Optional challenge media */}
              {project.challengeMedia && (
                <MediaRenderer src={project.challengeMedia} caption={project.challengeMediaCaption} />
              )}
            </motion.section>

            {/* Process section with media for each step */}
            <motion.section variants={staggerChildVariants} className="mb-24">
              <motion.h2 variants={staggerChildVariants} className="text-sm text-gray-400 uppercase tracking-wider mb-12">The Solution Process</motion.h2>
              
              {project.process.map((step, index) => (
                <motion.div 
                  key={index}
                  variants={staggerChildVariants}
                  className="mb-16"
                >
                  <h3 className="text-2xl font-light mb-4 text-gray-800">{index + 1}. {step.title}</h3>
                  <p className="text-lg text-gray-700 leading-relaxed mb-8">{step.description}</p>
                  
                  {/* Optional step media */}
                  {step.media && (
                    <MediaRenderer src={step.media} caption={step.mediaCaption} />
                  )}
                </motion.div>
              ))}
            </motion.section>

            {/* Outcome section with optional media */}
            <motion.section variants={staggerChildVariants} className="mb-24">
              <motion.h2 variants={staggerChildVariants} className="text-sm text-gray-400 uppercase tracking-wider mb-6">The Outcome</motion.h2>
              <motion.div variants={staggerChildVariants} className="mb-12">
                {typeof project.outcome === 'string' ? (
                  <p className="text-xl leading-relaxed text-gray-700">
                    {project.outcome}
                  </p>
                ) : (
                  <ul className="text-xl leading-relaxed text-gray-700 space-y-4">
                    {project.outcome.map((item, index) => (
                      <li key={index} className="flex">
                        <span className="mr-2">•</span>
                        <span>{item.replace(/^• /, '')}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </motion.div>
              
              {/* Optional outcome media */}
              {project.outcomeMedia && (
                <MediaRenderer src={project.outcomeMedia} caption={project.outcomeMediaCaption} />
              )}
            </motion.section>
            
            {/* Gallery section - only shown if project has gallery */}
            {project.gallery && project.gallery.length > 0 && (
              <motion.section variants={staggerChildVariants} className="mb-24">
                <motion.h2 variants={staggerChildVariants} className="text-sm text-gray-400 uppercase tracking-wider mb-8">Project Gallery</motion.h2>
                
                {/* Current main image with navigation */}
                <motion.div variants={staggerChildVariants} className="relative mb-8">
                  <div className="relative overflow-hidden rounded-lg bg-gray-100">
                    {getMediaType(project.gallery[currentImageIndex]) === 'video' ? (
                      <video 
                        src={project.gallery[currentImageIndex]} 
                        alt={`${project.title} - video ${currentImageIndex + 1}`}
                        className="w-full object-contain mx-auto" 
                        style={{ maxHeight: '80vh' }}
                        controls
                        autoPlay
                        loop
                      />
                    ) : (
                      <img 
                        src={project.gallery[currentImageIndex]} 
                        alt={`${project.title} - image ${currentImageIndex + 1}`}
                        className="w-full object-contain mx-auto" 
                        style={{ maxHeight: '80vh' }}
                      />
                    )}
                    
                    <div className="absolute inset-0 flex items-center justify-between px-4 opacity-0 hover:opacity-100 transition-opacity">
                      <button
                        onClick={goToPrevImage}
                        className="p-2 rounded-full bg-white bg-opacity-70 hover:bg-opacity-100 transition-colors shadow-md"
                        aria-label="Previous image"
                      >
                        <ChevronLeft className="w-6 h-6 text-gray-800" />
                      </button>
                      
                      <button
                        onClick={goToNextImage}
                        className="p-2 rounded-full bg-white bg-opacity-70 hover:bg-opacity-100 transition-colors shadow-md"
                        aria-label="Next image"
                      >
                        <ChevronRight className="w-6 h-6 text-gray-800" />
                      </button>
                    </div>
                    
                    <button
                      onClick={toggleFullscreen}
                      className="absolute bottom-4 right-4 p-2 rounded-full bg-white bg-opacity-70 hover:bg-opacity-100 transition-colors shadow-md"
                      aria-label="View fullscreen"
                    >
                      <Maximize className="w-5 h-5 text-gray-800" />
                    </button>
                  </div>
                  
                  <div className="mt-4 text-center">
                    <p className="text-gray-500 font-medium">{currentImageIndex + 1} / {project.gallery.length}</p>
                  </div>
                </motion.div>

                {/* Thumbnail navigation */}
                <motion.div variants={staggerChildVariants} className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 gap-4">
                  {project.gallery.map((media, index) => (
                    <div 
                      key={index} 
                      className={`cursor-pointer rounded-md overflow-hidden border-2 ${
                        currentImageIndex === index ? 'border-blue-500' : 'border-transparent'
                      }`}
                      onClick={() => setCurrentImageIndex(index)}
                    >
                      {getMediaType(media) === 'video' ? (
                        <div className="relative w-full h-20">
                          <video 
                            src={media} 
                            className="w-full h-full object-cover"
                          />
                          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30">
                            <div className="w-6 h-6 rounded-full border-2 border-white flex items-center justify-center">
                              <div className="w-0 h-0 border-l-4 border-l-white border-t-3 border-t-transparent border-b-3 border-b-transparent ml-0.5"></div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <img 
                          src={media} 
                          alt={`${project.title} thumbnail ${index + 1}`} 
                          className="w-full h-20 object-cover"
                        />
                      )}
                    </div>
                  ))}
                </motion.div>
              </motion.section>
            )}

            {/* Next/Prev navigation */}
            <motion.div variants={staggerChildVariants} className="border-t border-gray-200 pt-12 mt-24">
              <div className="flex flex-col sm:flex-row justify-between items-center">
                <button 
                  className="flex items-center text-gray-600 hover:text-black transition-colors mb-4 sm:mb-0"
                  onClick={handlePrevProject}
                >
                  <ArrowLeft className="w-5 h-5 mr-2" />
                  <span className="font-medium">Previous: {prevProject.title}</span>
                </button>
                <button 
                  className="flex items-center text-gray-600 hover:text-black transition-colors"
                  onClick={handleNextProject}
                >
                  <span className="font-medium">Next: {nextProject.title}</span>
                  <ArrowRight className="w-5 h-5 ml-2" />
                </button>
              </div>
            </motion.div>
          </motion.div>
        </motion.div>
        
        {/* Fullscreen image viewer */}
        <FullScreenMediaViewer />
      </motion.div>
    </AnimatePresence>
  );
};

export default ProjectPage;